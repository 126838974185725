import React, { useEffect, useState } from "react";
import Estimate from "./Estimation/Estimate";
import Preloader from "./UI/Preloader/Preloader";

const HomePage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Estimate />
        </>
      )}
    </div>
  );
};

export default HomePage;
