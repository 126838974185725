import Small from "../../Assets/Images/estimate/Small.jpg";
import Medium from "../../Assets/Images/estimate/Medium.jpg";
import Large from "../../Assets/Images/estimate/Large.jpg";
import MVP from "../../Assets/Images/estimate/mvp.png";
import Standard from "../../Assets/Images/estimate/basic.png";
import Polished from "../../Assets/Images/estimate/polished.png";
import Email from "../../Assets/Images/estimate/email.png";
import Facebook from "../../Assets/Images/estimate/facebook.png";
import Twitter from "../../Assets/Images/estimate/twitter.png";
import Google from "../../Assets/Images/estimate/google.png";
import LinkedIn from "../../Assets/Images/estimate/linkedIn.png";
import Github from "../../Assets/Images/estimate/github.png";
import UserInvitationEmail from "../../Assets/Images/estimate/userinvitationemail.png";
import MultiTenantAccount from "../../Assets/Images/estimate/multitenantaccount.png";
import Subdomains from "../../Assets/Images/estimate/subdomains.png";
import Dashboard from "../../Assets/Images/estimate/dashboard.png";
import CustomDomains from "../../Assets/Images/estimate/customdomains.png";
import ActivityFeed from "../../Assets/Images/estimate/activityfeed.png";
import MediaUpload from "../../Assets/Images/estimate/mediaupload.png";
import Userprofile from "../../Assets/Images/estimate/userprofile.png";
import TransactionEmail from "../../Assets/Images/estimate/transactionemail.png";
import Tags from "../../Assets/Images/estimate/tags.png";
import Rating from "../../Assets/Images/estimate/rating.png";
import MediaManipulating from "../../Assets/Images/estimate/mediamanipulating.png";
import Searching from "../../Assets/Images/estimate/searching.png";
import AppIcon from "../../Assets/Images/estimate/appicon.png";
import CloudSync from "../../Assets/Images/estimate/cloudsync.png";
import DeviceSensor from "../../Assets/Images/estimate/devicesensor.png";
import Barcode from "../../Assets/Images/estimate/barcode.png";
import HealthData from "../../Assets/Images/estimate/healthdata.png";
import AppleWatch from "../../Assets/Images/estimate/applewatch.png";
import Calendaring from "../../Assets/Images/estimate/calendaring.png";
import MapData from "../../Assets/Images/estimate/mapdata.png";
import MapMarker from "../../Assets/Images/estimate/mapmarker.png";
import Booking from "../../Assets/Images/estimate/booking.png";
import Messaging from "../../Assets/Images/estimate/messaging.png";
import Forums from "../../Assets/Images/estimate/forums.png";
import SocialSharing from "../../Assets/Images/estimate/socialsharing.png";
import FacebookOpenGraph from "../../Assets/Images/estimate/facebookopengraph.png";
import SubscriptionPlan from "../../Assets/Images/estimate/subscriptionplan.png";
import UserMarketPlace from "../../Assets/Images/estimate/usermarketplace.png";
import ProductManagement from "../../Assets/Images/estimate/productmanagement.png";
import PushNotifications from "../../Assets/Images/estimate/pushnotifications.png";
import ShoppingCart from "../../Assets/Images/estimate/shoppingcart.png";
import InAppPurchase from "../../Assets/Images/estimate/inapppurchase.png";
import PaymentCollection from "../../Assets/Images/estimate/paymentcollection.png";
import PaymentProcess from "../../Assets/Images/estimate/paymentprocess.png";
import Cms from "../../Assets/Images/estimate/cms.png";
import UserAdminPages from "../../Assets/Images/estimate/useradminpages.png";
import Moderation from "../../Assets/Images/estimate/moderation.png";
import PerformanceMonitoring from "../../Assets/Images/estimate/performancemonitoring.png";
import Intercom from "../../Assets/Images/estimate/intercom.png";
import UsageAnalytics from "../../Assets/Images/estimate/usageanalytics.png";
import CrashReport from "../../Assets/Images/estimate/crashreport.png";
import MultilingualSupport from "../../Assets/Images/estimate/multilingualsupport.png";
import Api from "../../Assets/Images/estimate/api.png";
import ThirdParty from "../../Assets/Images/estimate/thirdparty.png";
import SmsMessaging from "../../Assets/Images/estimate/smsmessaging.png";
import PhoneNumberMasking from "../../Assets/Images/estimate/phonenumbermasking.png";
import SslCertificate from "../../Assets/Images/estimate/sslcertificate.png";
import DosProtection from "../../Assets/Images/estimate/dosprotection.png";
import TwoFactorAuth from "../../Assets/Images/estimate/twofectorauth.png";

export const EstimationData = {
  size: {},
  ui_level: {},
  user_accounts: [],
  user_content: [],
  features: [],
  dates_locations: [],
  social_engagement: [],
  billing_ecommerce: [],
  feed_back: [],
  external_apis: [],
  security: [],
};
export const contentForWeb = [
  {
    content_id: 1,
    name: "size",
    heading: "How large is your business?",
    content_data: [
      {
        id: 1,
        days: 10,
        title: "Small",
        src: Small,
      },
      {
        id: 2,
        days: 30,
        title: "Medium",
        src: Medium,
      },
      {
        id: 3,
        days: 50,
        title: "Large",
        src: Large,
      },
    ],
  },
  {
    content_id: 2,
    name: "ui_level",
    heading: "Which UI level would you prefer?",
    content_data: [
      {
        id: 4,
        days: 30,
        title: "MVP",
        src: MVP,
      },
      {
        id: 5,
        days: 50,
        title: "Standard",
        src: Standard,
      },
      {
        id: 6,
        days: 70,
        title: "Polished",
        src: Polished,
      },
    ],
  },
  {
    content_id: 3,
    name: "user_accounts",
    heading: "Users & Accounts",
    content_data: [
      {
        id: 7,
        days: 1,
        title: "Email / Password Sign Up",
        src: Email,
      },
      {
        id: 8,
        days: 2,
        title: "Facebook Sign Up",
        src: Facebook,
      },
      {
        id: 9,
        days: 2,
        title: "Twitter Sign Up",
        src: Twitter,
      },
      {
        id: 10,
        days: 2,
        title: "Google Sign Up",
        src: Google,
      },
      {
        id: 11,
        days: 2,
        title: "LinkedIn Sign Up",
        src: LinkedIn,
      },
      {
        id: 12,
        days: 2,
        title: "Github Sign Up",
        src: Github,
      },
      {
        id: 13,
        days: 2,
        title: "User Invitation Emails",
        src: UserInvitationEmail,
      },
      {
        id: 14,
        days: 3,
        title: "Multi-tenant Accounts",
        src: MultiTenantAccount,
      },
      {
        id: 15,
        days: 4,
        title: "Subdomains",
        src: Subdomains,
      },
      {
        id: 16,
        days: 4,
        title: "Custom Domains",
        src: CustomDomains,
      },
    ],
  },
  {
    content_id: 4,
    name: "user_content",
    heading: "User specific content",
    content_data: [
      {
        id: 17,
        days: 5,
        title: "Dashboard",
        src: Dashboard,
      },
      {
        id: 18,
        days: 4,
        title: "Activity Feed",
        src: ActivityFeed,
      },
      {
        id: 19,
        days: 4,
        title: "File Uploading",
        src: MediaUpload,
      },
      {
        id: 20,
        days: 2,
        title: "User Profiles",
        src: Userprofile,
      },
      {
        id: 21,
        days: 2,
        title: "Transaction Emails",
        src: TransactionEmail,
      },
      {
        id: 22,
        days: 2,
        title: "Tags",
        src: Tags,
      },
      {
        id: 23,
        days: 5,
        title: "Ratings or reviews",
        src: Rating,
      },
      {
        id: 24,
        days: 5,
        title: "Audio/Video processing",
        src: MediaManipulating,
      },
      {
        id: 25,
        days: 5,
        title: "Free text searching",
        src: Searching,
      },
    ],
  },
  {
    content_id: 5,
    name: "dates_locations",
    heading: "Locations & Dates",
    content_data: [
      {
        id: 26,
        days: 7,
        title: "Calendaring",
        src: Calendaring,
      },
      {
        id: 27,
        days: 3,
        title: "Display of Map data / Geolocation",
        src: MapData,
      },
      {
        id: 28,
        days: 3,
        title: "Display of custom map markers/regions",
        src: MapMarker,
      },
      {
        id: 29,
        days: 8,
        title: "Scheduling",
        src: Booking,
      },
    ],
  },
  {
    content_id: 6,
    name: "social_engagement",
    heading: "Social Engagements",
    content_data: [
      {
        id: 30,
        days: 6,
        title: "Messaging",
        src: Messaging,
      },
      {
        id: 31,
        days: 5,
        title: "Forums or commenting",
        src: Forums,
      },
      {
        id: 32,
        days: 2,
        title: "Social Sharing",
        src: SocialSharing,
      },
      {
        id: 33,
        days: 5,
        title: "Push to Facebook Open Graph",
        src: FacebookOpenGraph,
      },
    ],
  },
  {
    content_id: 7,
    name: "billing_ecommerce",
    heading: "eCommerce & Fintech",
    content_data: [
      {
        id: 34,
        days: 8,
        title: "Subscription plans",
        src: SubscriptionPlan,
      },
      {
        id: 35,
        days: 5,
        title: "Payment processing",
        src: PaymentProcess,
      },
      {
        id: 36,
        days: 8,
        title: "Shopping Cart",
        src: ShoppingCart,
      },
      {
        id: 37,
        days: 12,
        title: "User Marketplace",
        src: UserMarketPlace,
      },
      {
        id: 38,
        days: 4,
        title: "Product Management",
        src: ProductManagement,
      },
    ],
  },
  {
    content_id: 8,
    name: "feed_back",
    heading: "Admin, Feedback & Analytics",
    content_data: [
      {
        id: 39,
        days: 7,
        title: "CMS Integration",
        src: Cms,
      },
      {
        id: 40,
        days: 3,
        title: "User Admin pages",
        src: UserAdminPages,
      },
      {
        id: 41,
        days: 4,
        title: "Moderation / Content Approval",
        src: Moderation,
      },
      {
        id: 42,
        days: 3,
        title: "Intercom",
        src: Intercom,
      },
      {
        id: 43,
        days: 3,
        title: "Usage Analytics",
        src: UsageAnalytics,
      },
      {
        id: 44,
        days: 0,
        title: "Crash Reporting",
        src: CrashReport,
      },
      {
        id: 45,
        days: 1,
        title: "Performance Monitoring",
        src: PerformanceMonitoring,
      },
      {
        id: 46,
        days: 4,
        title: "Multilingual Support",
        src: MultilingualSupport,
      },
    ],
  },
  {
    content_id: 9,
    name: "external_apis",
    heading: "External APIs and Integrations",
    content_data: [
      {
        id: 47,
        days: 6,
        title: "Connect to one or more third party services",
        src: ThirdParty,
      },
      {
        id: 48,
        days: 8,
        title: "An API for others to integrate with your app",
        src: Api,
      },
      {
        id: 49,
        days: 4,
        title: "SMS Messaging",
        src: SmsMessaging,
      },
      {
        id: 50,
        days: 4,
        title: "Phone Number Masking",
        src: PhoneNumberMasking,
      },
    ],
  },
  {
    content_id: 10,
    name: "security",
    heading: "Security",
    content_data: [
      {
        id: 51,
        days: 3,
        title: "SSL Certificate based Security",
        src: SslCertificate,
      },
      {
        id: 52,
        days: 5,
        title: "DoS protection",
        src: DosProtection,
      },
      {
        id: 53,
        days: 5,
        title: "Two Factor Authentication",
        src: TwoFactorAuth,
      },
    ],
  },
];
export const contentForIos = [
  {
    content_id: 1,
    name: "size",
    heading: "How large is your business?",
    content_data: [
      {
        id: 1,
        days: 10,
        title: "Small",
        src: Small,
      },
      {
        id: 2,
        days: 30,
        title: "Medium",
        src: Medium,
      },
      {
        id: 3,
        days: 50,
        title: "Large",
        src: Large,
      },
    ],
  },
  {
    content_id: 2,
    name: "ui_level",
    heading: "Which UI level would you prefer?",
    content_data: [
      {
        id: 4,
        days: 30,
        title: "MVP",
        src: MVP,
      },
      {
        id: 5,
        days: 50,
        title: "Standard",
        src: Standard,
      },
      {
        id: 6,
        days: 70,
        title: "Polished",
        src: Polished,
      },
    ],
  },
  {
    content_id: 3,
    name: "user_accounts",
    heading: "Users & Accounts",
    content_data: [
      {
        id: 7,
        days: 1,
        title: "Email / Password Sign Up",
        src: Email,
      },
      {
        id: 8,
        days: 2,
        title: "Facebook Sign Up",
        src: Facebook,
      },
      {
        id: 9,
        days: 2,
        title: "Twitter Sign Up",
        src: Twitter,
      },
      {
        id: 10,
        days: 2,
        title: "Google Sign Up",
        src: Google,
      },
      {
        id: 11,
        days: 2,
        title: "LinkedIn Sign Up",
        src: LinkedIn,
      },
      {
        id: 12,
        days: 2,
        title: "Github Sign Up",
        src: Github,
      },
    ],
  },
  {
    content_id: 4,
    name: "user_content",
    heading: "User specific content",
    content_data: [
      {
        id: 13,
        days: 4,
        title: "Activity Feed",
        src: ActivityFeed,
      },
      {
        id: 14,
        days: 4,
        title: "File Uploading",
        src: MediaUpload,
      },
      {
        id: 15,
        days: 2,
        title: "User Profiles",
        src: Userprofile,
      },
      {
        id: 16,
        days: 2,
        title: "Tags",
        src: Tags,
      },
      {
        id: 17,
        days: 5,
        title: "Ratings or reviews",
        src: Rating,
      },
      {
        id: 18,
        days: 7,
        title: "Audio/Video processing",
        src: MediaManipulating,
      },
      {
        id: 19,
        days: 3,
        title: "Free text searching",
        src: Searching,
      },
    ],
  },
  {
    content_id: 5,
    name: "features",
    heading: "Mobile specific features",
    content_data: [
      {
        id: 20,
        days: 7,
        title: "App Icon Design",
        src: AppIcon,
      },
      {
        id: 21,
        days: 5,
        title: "Cloud Syncing",
        src: CloudSync,
      },
      {
        id: 22,
        days: 5,
        title: "Device Sensor Data",
        src: DeviceSensor,
      },
      {
        id: 23,
        days: 2,
        title: "Barcodes or QR Codes",
        src: Barcode,
      },
      {
        id: 24,
        days: 4,
        title: "Health Data",
        src: HealthData,
      },
      {
        id: 25,
        days: 7,
        title: "Apple Watch",
        src: AppleWatch,
      },
    ],
  },
  {
    content_id: 6,
    name: "dates_locations",
    heading: "Locations & Dates",
    content_data: [
      {
        id: 26,
        days: 6,
        title: "Calendaring",
        src: Calendaring,
      },
      {
        id: 27,
        days: 3,
        title: "Display of Map data / Geolocation",
        src: MapData,
      },
      {
        id: 28,
        days: 3,
        title: "Display of custom map markers/regions",
        src: MapMarker,
      },
      {
        id: 29,
        days: 5,
        title: "Scheduling",
        src: Booking,
      },
    ],
  },
  {
    content_id: 7,
    name: "social_engagement",
    heading: "Social Engagements",
    content_data: [
      {
        id: 30,
        days: 5,
        title: "Messaging",
        src: Messaging,
      },
      {
        id: 31,
        days: 5,
        title: "Forums or commenting",
        src: Forums,
      },
      {
        id: 32,
        days: 1,
        title: "Social Sharing",
        src: SocialSharing,
      },
      {
        id: 33,
        days: 3,
        title: "Push to Facebook Open Graph",
        src: FacebookOpenGraph,
      },
      {
        id: 34,
        days: 3,
        title: "Push Notifications",
        src: PushNotifications,
      },
    ],
  },
  {
    content_id: 8,
    name: "billing_ecommerce",
    heading: "eCommerce & Fintech",
    content_data: [
      {
        id: 35,
        days: 5,
        title: "Shopping Cart",
        src: ShoppingCart,
      },
      {
        id: 36,
        days: 5,
        title: "In-App Purchasing",
        src: InAppPurchase,
      },
      {
        id: 37,
        days: 3,
        title: "Payment Information Collection",
        src: PaymentCollection,
      },
      {
        id: 38,
        days: 5,
        title: "Payment processing",
        src: PaymentProcess,
      },
    ],
  },
  {
    content_id: 9,
    name: "feed_back",
    heading: "Admin, Feedback & Analytics",
    content_data: [
      {
        id: 39,
        days: 3,
        title: "Intercom",
        src: Intercom,
      },
      {
        id: 40,
        days: 3,
        title: "Usage Analytics",
        src: UsageAnalytics,
      },
      {
        id: 41,
        days: 1,
        title: "Crash Reporting",
        src: CrashReport,
      },
      {
        id: 42,
        days: 4,
        title: "Multilingual Support",
        src: MultilingualSupport,
      },
    ],
  },
  {
    content_id: 10,
    name: "external_apis",
    heading: "External APIs and Integrations",
    content_data: [
      {
        id: 43,
        days: 3,
        title: "Connect to one or more third party services",
        src: ThirdParty,
      },
      {
        id: 44,
        days: 4,
        title: "SMS Messaging",
        src: SmsMessaging,
      },
      {
        id: 45,
        days: 4,
        title: "Phone Number Masking",
        src: PhoneNumberMasking,
      },
    ],
  },
  {
    content_id: 11,
    name: "security",
    heading: "Security",
    content_data: [
      {
        id: 46,
        days: 5,
        title: "Two Factor Authentication",
        src: TwoFactorAuth,
      },
    ],
  },
];
export const contentForAndroid = [
  {
    content_id: 1,
    name: "size",
    heading: "How large is your business?",
    content_data: [
      {
        id: 1,
        days: 10,
        title: "Small",
        src: Small,
      },
      {
        id: 2,
        days: 30,
        title: "Medium",
        src: Medium,
      },
      {
        id: 3,
        days: 50,
        title: "Large",
        src: Large,
      },
    ],
  },
  {
    content_id: 2,
    name: "ui_level",
    heading: "Which UI level would you prefer?",
    content_data: [
      {
        id: 4,
        days: 30,
        title: "MVP",
        src: MVP,
      },
      {
        id: 5,
        days: 50,
        title: "Standard",
        src: Standard,
      },
      {
        id: 6,
        days: 70,
        title: "Polished",
        src: Polished,
      },
    ],
  },
  {
    content_id: 3,
    name: "user_accounts",
    heading: "Users & Accounts",
    content_data: [
      {
        id: 7,
        days: 1,
        title: "Email / Password Sign Up",
        src: Email,
      },
      {
        id: 8,
        days: 2,
        title: "Facebook Sign Up",
        src: Facebook,
      },
      {
        id: 9,
        days: 2,
        title: "Twitter Sign Up",
        src: Twitter,
      },
      {
        id: 10,
        days: 2,
        title: "Google Sign Up",
        src: Google,
      },
      {
        id: 11,
        days: 2,
        title: "LinkedIn Sign Up",
        src: LinkedIn,
      },
      {
        id: 12,
        days: 2,
        title: "Github Sign Up",
        src: Github,
      },
    ],
  },
  {
    content_id: 4,
    name: "user_content",
    heading: "User specific content",
    content_data: [
      {
        id: 13,
        days: 4,
        title: "Activity Feed",
        src: ActivityFeed,
      },
      {
        id: 14,
        days: 4,
        title: "File Uploading",
        src: MediaUpload,
      },
      {
        id: 15,
        days: 2,
        title: "User Profiles",
        src: Userprofile,
      },
      {
        id: 16,
        days: 2,
        title: "Tags",
        src: Tags,
      },
      {
        id: 17,
        days: 5,
        title: "Ratings or reviews",
        src: Rating,
      },
      {
        id: 18,
        days: 7,
        title: "Audio/Video processing",
        src: MediaManipulating,
      },
      {
        id: 19,
        days: 3,
        title: "Free text searching",
        src: Searching,
      },
    ],
  },
  {
    content_id: 5,
    name: "features",
    heading: "Mobile specific features",
    content_data: [
      {
        id: 20,
        days: 7,
        title: "App Icon Design",
        src: AppIcon,
      },
      {
        id: 21,
        days: 5,
        title: "Cloud Syncing",
        src: CloudSync,
      },
      {
        id: 22,
        days: 5,
        title: "Device Sensor Data",
        src: DeviceSensor,
      },
      {
        id: 23,
        days: 2,
        title: "Barcodes or QR Codes",
        src: Barcode,
      },
      {
        id: 24,
        days: 4,
        title: "Health Data",
        src: HealthData,
      },
    ],
  },
  {
    content_id: 6,
    name: "dates_locations",
    heading: "Locations & Dates",
    content_data: [
      {
        id: 25,
        days: 6,
        title: "Calendaring",
        src: Calendaring,
      },
      {
        id: 26,
        days: 3,
        title: "Display of Map data / Geolocation",
        src: MapData,
      },
      {
        id: 27,
        days: 3,
        title: "Display of custom map markers/regions",
        src: MapMarker,
      },
      {
        id: 28,
        days: 5,
        title: "Scheduling",
        src: Booking,
      },
    ],
  },
  {
    content_id: 7,
    name: "social_engagement",
    heading: "Social Engagements",
    content_data: [
      {
        id: 29,
        days: 5,
        title: "Messaging",
        src: Messaging,
      },
      {
        id: 30,
        days: 5,
        title: "Forums or commenting",
        src: Forums,
      },
      {
        id: 31,
        days: 1,
        title: "Social Sharing",
        src: SocialSharing,
      },
      {
        id: 32,
        days: 3,
        title: "Push to Facebook Open Graph",
        src: FacebookOpenGraph,
      },
      {
        id: 33,
        days: 3,
        title: "Push Notifications",
        src: PushNotifications,
      },
    ],
  },
  {
    content_id: 8,
    name: "billing_ecommerce",
    heading: "eCommerce & Fintech",
    content_data: [
      {
        id: 34,
        days: 5,
        title: "Shopping Cart",
        src: ShoppingCart,
      },
      {
        id: 35,
        days: 5,
        title: "In-App Purchasing",
        src: InAppPurchase,
      },
      {
        id: 36,
        days: 3,
        title: "Payment Information Collection",
        src: PaymentCollection,
      },
      {
        id: 37,
        days: 5,
        title: "Payment processing",
        src: PaymentProcess,
      },
    ],
  },
  {
    content_id: 9,
    name: "feed_back",
    heading: "Admin, Feedback & Analytics",
    content_data: [
      {
        id: 38,
        days: 3,
        title: "Intercom",
        src: Intercom,
      },
      {
        id: 39,
        days: 3,
        title: "Usage Analytics",
        src: UsageAnalytics,
      },
      {
        id: 40,
        days: 1,
        title: "Crash Reporting",
        src: CrashReport,
      },
      {
        id: 41,
        days: 4,
        title: "Multilingual Support",
        src: MultilingualSupport,
      },
    ],
  },
  {
    content_id: 10,
    name: "external_apis",
    heading: "External APIs and Integrations",
    content_data: [
      {
        id: 42,
        days: 3,
        title: "Connect to one or more third party services",
        src: ThirdParty,
      },
      {
        id: 43,
        days: 4,
        title: "SMS Messaging",
        src: SmsMessaging,
      },
      {
        id: 44,
        days: 4,
        title: "Phone Number Masking",
        src: PhoneNumberMasking,
      },
    ],
  },
  {
    content_id: 11,
    name: "security",
    heading: "Security",
    content_data: [
      {
        id: 45,
        days: 5,
        title: "Two Factor Authentication",
        src: TwoFactorAuth,
      },
    ],
  },
];
