import React from "react";
import "./Estimate.css";

const FormView = ({ selectedData, rate }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center border-bottom mt-3">
        <p className="fs-5 fw-bold">Features</p>
        <p className="fs-5 fw-bold">Base Day(s)</p>
      </div>
      <div className="d-flex justify-content-between border-bottom my-3">
        <p className="fs-5 fw-bold">1. How large is your business?</p>
      </div>
      {selectedData?.size.length > 0 && (
        <div className="d-flex justify-content-between">
          <p className="my-2">{selectedData?.size[0]?.title}</p>
          <p className="my-2 border px-4 py-1">{selectedData?.size[0]?.days}</p>
        </div>
      )}
      <div className="d-flex justify-content-between border-bottom my-3">
        <p className="fs-5 fw-bold">2. Which UI level would you prefer?</p>
      </div>
      {selectedData?.ui_level.length > 0 && (
        <div className="d-flex justify-content-between">
          <p className="my-2">{selectedData?.ui_level[0]?.title}</p>
          <p className="my-2 border px-4 py-1">
            {selectedData?.ui_level[0]?.days}
          </p>
        </div>
      )}
      <div className="d-flex justify-content-between border-bottom my-3">
        <p className="fs-5 fw-bold">3. Users & Accounts</p>
      </div>
      {selectedData?.user_accounts.map((curr, index) => (
        <div key={`curr-${index}`} className="d-flex justify-content-between">
          <p className="my-2">{curr.title}</p>
          <p className="my-2 border px-4 py-1">{curr.days}</p>
        </div>
      ))}
      <div className="d-flex justify-content-between border-bottom my-3">
        <p className="fs-5 fw-bold">4. User specific content</p>
      </div>
      {selectedData?.user_content.map((curr, index) => (
        <div key={`curr-${index}`} className="d-flex justify-content-between">
          <p className="my-2">{curr.title}</p>
          <p className="my-2 border px-4 py-1">{curr.days}</p>
        </div>
      ))}
      {selectedData.features.length > 0 ? (
        <>
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">5. Mobile specific features</p>
          </div>
          {selectedData?.features.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">6. Locations & Dates</p>
          </div>
          {selectedData?.dates_locations.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">7. Social Engagements</p>
          </div>
          {selectedData?.social_engagement.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">8. eCommerce & Fintech</p>
          </div>
          {selectedData?.billing_ecommerce.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">9. Admin, Feedback & Analytics</p>
          </div>
          {selectedData?.feed_back.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">10. External APIs and Integrations</p>
          </div>
          {selectedData?.external_apis.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">11. Security</p>
          </div>
          {selectedData?.security.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">5. Locations & Dates</p>
          </div>
          {selectedData?.dates_locations.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">6. Social Engagements</p>
          </div>
          {selectedData?.social_engagement.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">7. eCommerce & Fintech</p>
          </div>
          {selectedData?.billing_ecommerce.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">8. Admin, Feedback & Analytics</p>
          </div>
          {selectedData?.feed_back.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">9. External APIs and Integrations</p>
          </div>
          {selectedData?.external_apis.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between border-bottom my-3">
            <p className="fs-5 fw-bold">10. Security</p>
          </div>
          {selectedData?.security.map((curr, index) => (
            <div
              key={`curr-${index}`}
              className="d-flex justify-content-between"
            >
              <p className="my-2">{curr.title}</p>
              <p className="my-2 border px-4 py-1">{curr.days}</p>
            </div>
          ))}
        </>
      )}
      <div className="d-flex justify-content-between border-bottom my-3">
        <p className="fs-5 fw-bold">Approximation</p>
        <p className="fs-5 fw-bold">Rate</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="my-2">Day Rate</p>
        <p className="my-2 border px-4 py-1">$240</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="my-2">Amount Per Estimation</p>
        <p className="my-2 border px-4 py-1">${rate}</p>
      </div>
    </>
  );
};

export default FormView;
