import React, { useEffect, useState } from "react";
import "./Estimate.css";
import MainComponent from "./MainComponent";
import {
  contentForAndroid,
  contentForIos,
  contentForWeb,
} from "../constants/entimateContent";
import FormView from "./FormView";
import HubexLogo from "../../Assets/Images/logo.png";

const Form = ({
  baseColor,
  webEstimate,
  setWebEstimate,
  webSelectedData,
  setWebSelectedData,
  iosEstimate,
  setIosEstimate,
  iOSSelectedData,
  setIOSSelectedData,
  androidEstimate,
  setAndroidEstimate,
  androidSelectedData,
  setAndroidSelectedData,
  rootElementId,
  weAppRate,
  iosAppRate,
  androidAppRate,
}) => {
  const [showCalculations, setShowCalculations] = useState(false);
  const [isWebCircle, setIsWebCircle] = useState([]);
  const [isIosCircle, setIsIosCircle] = useState([]);
  const [isAndCircle, setIsAndCircle] = useState([]);
  const [webIsSize, setWebIsSize] = useState(-1);
  const [iosIsSize, setIosIsSize] = useState(-1);
  const [andIsSize, setAndIsSize] = useState(-1);
  const [webIsLevel, setWebIsLevel] = useState(-1);
  const [AndIsLevel, setAndIsLevel] = useState(-1);
  const [iosIsLevel, setIosIsLevel] = useState(0);
  const [isWebAmount, setIsWebAmount] = useState(0);
  const [isIosAmount, setIsIosAmount] = useState(0);
  const [isAndAmount, setIsAndAmount] = useState(0);
  const [isWebAmount2, setIsWebAmount2] = useState(0);
  const [isIosAmount2, setIsIosAmount2] = useState(0);
  const [isAndAmount2, setIsAndAmount2] = useState(0);
  useEffect(() => {
    setIosEstimate(iosEstimate + isIosAmount + isIosAmount2);
    // eslint-disable-next-line
  }, [isIosAmount2, isIosAmount, setIosEstimate]);
  useEffect(() => {
    setWebEstimate(webEstimate + isWebAmount2 + isWebAmount);
    // eslint-disable-next-line
  }, [isWebAmount2, isWebAmount, setWebEstimate]);
  useEffect(() => {
    setAndroidEstimate(androidEstimate + isAndAmount + isAndAmount2);
    // eslint-disable-next-line
  }, [isAndAmount, isAndAmount2, setAndroidEstimate]);
  const PDF = () => {
    return (
      <div>
        <div className="w-50">
          <img src={HubexLogo} className="img-fluid" width="180px" alt="logo" />
        </div>
        <h1 className="text-center fw-bold my-5">Web Estimate</h1>
        <FormView rate={weAppRate} selectedData={webSelectedData} />
        <h1 className="text-center fw-bold my-5">Ios Estimate</h1>
        <FormView rate={iosAppRate} selectedData={iOSSelectedData} />
        <h1 className="text-center fw-bold my-5">Android Estimate</h1>
        <FormView rate={androidAppRate} selectedData={androidSelectedData} />
        <div className="d-flex justify-content-between fw-bold">
          <p className="my-2">Total Amount</p>
          <p className="my-2 border px-4 py-1">
            ${weAppRate + iosAppRate + androidAppRate}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {baseColor === 2 ? (
        <>
          <MainComponent
            content={contentForIos}
            selectedIndex={iOSSelectedData}
            setSelectedIndex={setIOSSelectedData}
            estimate={iosEstimate}
            setEstimate={setIosEstimate}
            isCircle={isIosCircle}
            setIsCircle={setIsIosCircle}
            isSize={iosIsSize}
            setIsSize={setIosIsSize}
            isLevel={iosIsLevel}
            setIsLevel={setIosIsLevel}
            singleAmount={isIosAmount}
            setSingleAmount={setIsIosAmount}
            singleAmount2={isIosAmount2}
            setSingleAmount2={setIsIosAmount2}
          />
          <div className="d-flex justify-content-center align-items-center p-5 text-light fw-bold">
            <div
              className="show-grid"
              onClick={() => setShowCalculations(!showCalculations)}
            >
              {showCalculations ? (
                <span>
                  Click to Hide Calculations
                  <i className="fas fa-chevron-down mx-2"></i>
                </span>
              ) : (
                <span>
                  Click to Show Calculations
                  <i className="fas fa-chevron-down mx-2"></i>
                </span>
              )}
            </div>
          </div>
          {showCalculations && (
            <div className="px-5 pb-5 w-100 m-auto">
              <div className="m-0 p-5 bg-white w-100 shadow-main">
                <FormView rate={iosAppRate} selectedData={iOSSelectedData} />
              </div>
            </div>
          )}
        </>
      ) : baseColor === 3 ? (
        <>
          <MainComponent
            content={contentForAndroid}
            selectedIndex={androidSelectedData}
            setSelectedIndex={setAndroidSelectedData}
            estimate={androidEstimate}
            setEstimate={setAndroidEstimate}
            isCircle={isAndCircle}
            setIsCircle={setIsAndCircle}
            isSize={andIsSize}
            setIsSize={setAndIsSize}
            isLevel={AndIsLevel}
            setIsLevel={setAndIsLevel}
            singleAmount={isAndAmount}
            setSingleAmount={setIsAndAmount}
            singleAmount2={isAndAmount2}
            setSingleAmount2={setIsAndAmount2}
          />
          <div className="d-flex justify-content-center align-items-center p-5 text-light fw-bold">
            <div
              className="show-grid"
              onClick={() => setShowCalculations(!showCalculations)}
            >
              {showCalculations ? (
                <span>
                  Click to Hide Calculations
                  <i className="fas fa-chevron-down mx-2"></i>
                </span>
              ) : (
                <span>
                  Click to Show Calculations
                  <i className="fas fa-chevron-down mx-2"></i>
                </span>
              )}
            </div>
          </div>
          {showCalculations && (
            <div className="px-5 pb-5 w-100 m-auto">
              <div className="m-0 p-5 bg-white w-100 shadow-main">
                <FormView
                  rate={androidAppRate}
                  selectedData={androidSelectedData}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <MainComponent
            content={contentForWeb}
            selectedIndex={webSelectedData}
            setSelectedIndex={setWebSelectedData}
            estimate={webEstimate}
            setEstimate={setWebEstimate}
            isCircle={isWebCircle}
            setIsCircle={setIsWebCircle}
            isSize={webIsSize}
            setIsSize={setWebIsSize}
            isLevel={webIsLevel}
            setIsLevel={setWebIsLevel}
            singleAmount={isWebAmount}
            setSingleAmount={setIsWebAmount}
            singleAmount2={isWebAmount2}
            setSingleAmount2={setIsWebAmount2}
          />
          <div className="d-flex justify-content-center align-items-center p-5 text-light fw-bold">
            <div
              className="show-grid"
              onClick={() => setShowCalculations(!showCalculations)}
            >
              {showCalculations ? (
                <span>
                  Click to Hide Calculations
                  <i className="fas fa-chevron-down mx-2"></i>
                </span>
              ) : (
                <span>
                  Click to Show Calculations
                  <i className="fas fa-chevron-down mx-2"></i>
                </span>
              )}
            </div>
          </div>
          {showCalculations && (
            <div className="px-5 pb-5 w-100 m-auto">
              <div className="m-0 p-5 bg-white w-100 shadow-main">
                <FormView rate={weAppRate} selectedData={webSelectedData} />
              </div>
            </div>
          )}
        </>
      )}
      <div style={{ display: "none" }}>
        <div id={rootElementId}>
          <PDF />
        </div>
      </div>
    </div>
  );
};

export default Form;
