import React from "react";
import { Col, Row } from "react-bootstrap";

const appSize = [1, 2, 3];
const appLevel = [4, 5, 6];
export const Content = ({
  setSingleAmount2,
  setSingleAmount,
  isLevel,
  setIsLevel,
  setIsCircle,
  isCircle,
  setIsSize,
  isSize,
  name,
  content_data,
  content_id,
  heading,
  selectedIndex,
  setSelectedIndex,
  setEstimate,
  estimate,
}) => {
  const isObject = Boolean(name === "size" || name === "ui_level");
  const handleCircle = (id, days) => {
    if (appSize.includes(id) || appLevel.includes(id)) {
      if (appLevel.includes(id)) {
        setIsLevel(id);
        setSingleAmount(days);
      } else {
        setIsSize(id);
        setSingleAmount2(days);
      }
    } else if (isCircle.includes(id)) {
      setEstimate(estimate - days);
      const data = isCircle.filter((item) => item !== id);
      setIsCircle(data);
    } else {
      setEstimate(estimate + days);
      setIsCircle([...isCircle, id]);
    }
  };

  const setData = (id, days, title) => {
    handleCircle(id, days);
    const oldData = isObject ? [] : selectedIndex[name];
    const IsAdded = oldData.find((e) => e.id === id);
    const data = {
      title,
      id,
      days,
    };

    if (isObject) {
      setSelectedIndex({ ...selectedIndex, [name]: [data] });
    } else if (IsAdded) {
      const data = oldData.filter((e) => e.id !== id);
      setSelectedIndex({ ...selectedIndex, [name]: [...data] });
    } else {
      setSelectedIndex({ ...selectedIndex, [name]: [...oldData, data] });
    }
  };

  return (
    <div className="p-5 w-100 m-auto">
      <Row className="m-0 text-center py-5 bg-white w-100 shadow-main">
        <p className="text-center mb-3 fs-1 fw-bold">
          {content_id}.&nbsp;{heading}
        </p>
        {content_data.map(({ title, id, src, days }, index) => (
          <Col
            md={3}
            key={`curr-${index}`}
            className="d-flex flex-column align-items-center flex-nowrap my-4"
          >
            <p className="my-4">{title}</p>
            <div
              className={`${
                (isCircle.includes(id) || isLevel === id || isSize === id) &&
                "checked-container"
              }`}
            >
              <img
                onClick={() => setData(id, days, title)}
                className={`rounded-circle ${
                  isCircle.includes(id) || isLevel === id || isSize === id
                    ? "checked-main-1"
                    : "checked-main"
                }`}
                src={src}
                alt={`content-${id}`}
                width="222"
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
