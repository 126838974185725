import React from "react";
import "./Estimate.css";
import { Content } from "./Content";

const MainComponent = ({
  setSingleAmount,
  setSingleAmount2,
  isLevel,
  setIsLevel,
  setIsCircle,
  isCircle,
  setIsSize,
  isSize,
  content,
  selectedIndex,
  setSelectedIndex,
  setEstimate,
  estimate,
}) => {
  return (
    <>
      {content.map((item, index) => (
        <div key={`item-${index}`}>
          <Content
            {...item}
            key={index}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setEstimate={setEstimate}
            estimate={estimate}
            isSize={isSize}
            setIsSize={setIsSize}
            isLevel={isLevel}
            setIsLevel={setIsLevel}
            isCircle={isCircle}
            setIsCircle={setIsCircle}
            setSingleAmount={setSingleAmount}
            setSingleAmount2={setSingleAmount2}
          />
        </div>
      ))}
    </>
  );
};

export default MainComponent;
